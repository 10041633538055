define("comunidades-nafin/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wWJNbydr",
    "block": "{\"symbols\":[],\"statements\":[[2,\"<div id=\\\"loading\\\">\\n\\t<div id=\\\"loader\\\">\\n\\t\\t<div id=\\\"shadow\\\"></div>\\n\\t\\t<div id=\\\"box\\\" class=\\\"box-theme\\\">\\n\\t\\t\\t<img src=\\\"https://acegif.com/wp-content/uploads/loading-9.gif\\\" alt=\\\"\\\">\\n\\t\\t</div>\\n\\t</div>\\t\\n</div>\"],[0,\"\\n\"],[2,\"\\n<div class=\\\"\\\" id=\\\"loading\\\">\\n    <div class=\\\"row\\\" align=\\\"center\\\" style=\\\"height:100vh\\\">\\n        <div class=\\\"col-12 mx-auto my-auto\\\">\\n            <img class=\\\"\\\" src=\\\"img/loading-23.gif\\\" style=\\\"width:250px\\\" alt=\\\"\\\"><br>\\n        </div>            \\n    </div>\\n</div>\\n\"],[0,\"\\n\\n\"],[6,\"style\"],[10,\"type\",\"text/css\"],[8],[0,\"\\n    #loading .progress {\\n        width: 96px;\\n        height: 17.6px;\\n        border-radius: 16px;\\n        color: #f08300;\\n        border: 1.6px solid;\\n        position: relative;\\n    }\\n\\n    #loading .progress::before {\\n        content: \\\"\\\";\\n        position: absolute;\\n        margin: 1.6px;\\n        inset: 0 100% 0 0;\\n        border-radius: inherit;\\n        background: currentColor;\\n        animation: progress-pf82op 2s infinite;\\n    }\\n\\n    @keyframes progress-pf82op {\\n        100% {\\n            inset: 0;\\n        }\\n    }\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"\"],[10,\"id\",\"loading\"],[10,\"style\",\"z-index:999999999\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"row\"],[10,\"align\",\"center\"],[10,\"style\",\"height:100vh\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-12 mx-auto my-auto\"],[8],[0,\"\\n            \"],[6,\"img\"],[10,\"src\",\"img/logo_preloader.png\"],[10,\"style\",\"width:80px; margin-bottom: 20px;\"],[8],[9],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"progress\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});