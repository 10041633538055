define("comunidades-nafin/routes/lms/administracion-usuarios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    params: {},
    _controller: null,
    headers: [{
      dataIndex: 'usuario_k',
      title: 'ID'
    }, {
      dataIndex: 'nombre_completo',
      title: 'Nombre'
    }, {
      dataIndex: 'email',
      title: 'Email'
    }, {
      dataIndex: 'datos_empresa',
      title: 'Datos de Empresa'
    },
    /*{ dataIndex: 'centro', title: 'Centro' },
    { dataIndex: 'departamento', title: 'Departamento' },
    { dataIndex: 'puesto', title: 'Puesto' }, */
    {
      dataIndex: 'username',
      title: 'Username'
    }, {
      dataIndex: 'rol',
      title: 'Rol(s)'
    }, // { dataIndex: 'estatus', title: 'Estatus' },
    {
      dataIndex: 'acciones',
      title: 'Acciones'
    }],
    idiomaTable: {
      "decimal": "",
      "emptyTable": "Sin registros",
      "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
      "infoEmpty": "Mosrando 0 to 0 de 0 registros",
      "infoFiltered": "(Filtro _MAX_ registros totales)",
      "infoPostFix": "",
      "thousands": ",",
      "lengthMenu": "Mostrar _MENU_ registros",
      "loadingRecords": "Cargando...",
      "processing": "",
      "search": "Buscar:",
      "zeroRecords": "Sin coincidencias",
      "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
      },
      "aria": {
        "sortAscending": ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
      }
    },
    config_table_usuarios: null,
    config_modal_usuario: {
      titulomodal: "Nuevo Usuario",
      usuario_k: 0,
      nombreusuario: "",
      apellidopaterno: "",
      apellidomaterno: "",
      genero: "",
      email: "",
      sucursal_k: "",
      departamento_k: "",
      puesto_k: "",
      visibleComunidad: true,
      username: "",
      rol_k: "",
      otros_roles: [],
      esEdicion: false
    },
    mdl_confirm: {
      descripcion: "",
      accion: "",
      titulo_boton: "",
      datos: {}
    },
    config_passwords: {
      password_uno: "",
      password_dos: "",
      notificacion: false,
      datos: {}
    },
    config_pantallas: {
      isPantallaUno: true,
      isPantallaDos: false
    },
    nombre_archivos: {
      centros: '',
      usuarios: ''
    },
    beforeModel: function beforeModel() {
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        self.loadData(controller);
        controller.set('headersTable', self.get("headers"));
        controller.set('sucursal_usuario', "");
        controller.set('config_modal_usuario', self.get("config_modal_usuario"));
        controller.set('mdl_confirm', self.get("mdl_confirm"));
        controller.set('config_passwords', self.get("config_passwords"));
        controller.set('config_pantallas', self.get("config_pantallas"));
        controller.set('btn_cargamasiva_siguiente', false);
        controller.set('msj_cargamasiva', '');
        controller.set('analisis_cargamasiva', '');
      }, 1000);
    },
    loadData: function loadData(controller) {
      var self = this;
      /* Carga de USUARIOS */

      self.carga_de_usuarios(controller);
      /* Carga de CENTROS */

      self.carga_sucursales(controller);
      /* Carga de DEPARTAMENTO */

      self.carga_departamento(controller);
      /* Carga de PUESTOS */

      self.carga_puestos(controller);
      /* Carga de COMUNIDADES */

      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarcomunidades',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('comunidades', response.data);
          controller.set('total_comunidad', response.numFilas);
          /* 
              Si la Empresa solo cuenta con una comunidad, no se muestra en el formulario y los usuarios se daran de alta con esa comunidad
           */

          if (response.numFilas > 1) {
            controller.set('isVisibleComunidades', '');
            controller.set('selectedComunidades', true);
          } else {
            controller.set('isVisibleComunidades', 'd-none');
            controller.set('selectedComunidades', false);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      /* Carga de ROLES */

      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarroles',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('roles', response.data);

          if (response.numFilas > 0) {
            controller.set('error_empresassoluciones', false);
          } else {
            controller.set('error_empresassoluciones', true);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      /* Solo ingresar minusculas */

      $(".solominusculas").keyup(function () {
        $(this).val($(this).val().toLowerCase());
      });
      /* Solo ingresar mayusculas */

      $(".solomayusculas").keyup(function () {
        $(this).val($(this).val().toUpperCase());
      });
      $('#nuevoUsuarioModal, #nuevoElementoModal, #confirm_delete_modal, #cambioPassModal').modal({
        show: false,
        keyboard: false,
        backdrop: 'static'
      });
      $('#nuevoUsuarioModal').on('shown.bs.modal', function () {
        $('#frm_nombre').trigger('focus');
      });
      $('#cambioPassModal').on('shown.bs.modal', function () {
        $('#frm_contrasenia_uno').trigger('focus');
      });
      $('#nuevoUsuarioModal').on('hidden.bs.modal', function (e) {
        self.form_nuevousuario_removeinvalid();
        self.form_nuevousuario_clearinput();
        self.config_modal_usuario = {
          titulomodal: "Nuevo Usuario",
          usuario_k: 0,
          nombreusuario: "",
          apellidopaterno: "",
          apellidomaterno: "",
          genero: "",
          email: "",
          sucursal_k: "",
          departamento_k: "",
          puesto_k: "",
          visibleComunidad: true,
          username: "",
          rol_k: "",
          otros_roles: [],
          esEdicion: false
        };
        controller.set('config_modal_usuario', self.get("config_modal_usuario"));
      });
      $('#cambioPassModal').on('hidden.bs.modal', function (e) {
        self.config_passwords = {
          password_uno: "",
          password_dos: "",
          notificacion: false,
          datos: {}
        };
        controller.set('config_passwords', self.get("config_passwords"));
      });
      $('#cargaMasivaModal').on('hidden.bs.modal', function (e) {
        self.config_pantallas = {
          isPantallaUno: true,
          isPantallaDos: false
        };

        self._controller.set('config_pantallas', self.get("config_pantallas"));
      });
      $("#frm_correo").on("keyup change", function (e) {
        $("#frm_username").val($(this).val());
      }); // Agregamos una nueva Sucursal en caso de seleccionar la opción 'OTRO'

      $("#frm_sucursal, #frm_departamento, #frm_puesto").on("change", function (e) {
        var sucursal = $(this).val().trim(),
            tipo_elemento = $(this).attr("data-tipo");

        if (sucursal === 'OTRO') {
          $('#nuevoElementoModal').modal('show');
          $('.lbl_nuevo_elemento').html(tipo_elemento);
          $('#tipoElemento').val(tipo_elemento);
        }
      });
      $('#nuevoElementoModal').on('hidden.bs.modal', function (e) {
        self.form_nuevoelemento_clearinput();
      });
      $('#nuevoElementoModal').on('shown.bs.modal', function () {
        $('#frm_elemento_nombre').trigger('focus');
      });
      $("input#frm_contrasenia_uno").on("focus keyup", function () {
        var password = $(this).val(); // Deberá ser mayor a 7 caracteres

        if (password.length > 7) {
          $("#pass_length").removeClass("invalid").addClass("valid");
        } else if (password.length > 0 && password.length < 8) {
          $("#pass_length").removeClass("valid").addClass("invalid");
        } // Deberá tener una letra minúscula.


        if (password.match(/[a-z]/)) {
          $("#password_minuscula").removeClass("invalid").addClass("valid");
        } else {
          $("#password_minuscula").removeClass("valid").addClass("invalid");
        } // Deberá tener una letra mayúscula.


        if (password.match(/[A-Z]/)) {
          $("#password_mayuscula").removeClass("invalid").addClass("valid");
        } else {
          $("#password_mayuscula").removeClass("valid").addClass("invalid");
        } // Deberá tener un número.


        if (password.match(/\d/)) {
          $("#password_numero").removeClass("invalid").addClass("valid");
        } else {
          $("#password_numero").removeClass("valid").addClass("invalid");
        } // Deberá tener un caracter especial


        if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
          $("#password_caracter").removeClass("invalid").addClass("valid");
        } else {
          $("#password_caracter").removeClass("valid").addClass("invalid");
        } // Removemos el status de las validaciones


        if (password.length === 0) {
          $("#pass_length").removeClass("valid").removeClass("invalid");
          $("#password_minuscula").removeClass("valid").removeClass("invalid");
          $("#password_mayuscula").removeClass("valid").removeClass("invalid");
          $("#password_numero").removeClass("valid").removeClass("invalid");
          $("#password_caracter").removeClass("valid").removeClass("invalid");
        }
      });
    },
    carga_de_usuarios: function carga_de_usuarios(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarusuarios',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('dataTable', response.data);
          setTimeout(function () {
            self.config_table_usuarios = $("#usuariosTable").DataTable({
              language: self.idiomaTable,
              retrieve: true,
              scrollY: '480px',
              scrollCollapse: true,
              paging: false,
              columnDefs: [{
                target: 0,
                visible: false
              }],
              order: []
            }); // Edición de Usuario

            $('#usuariosTable tbody').on('click', '.btn_editarusuario', function (event) {
              // Obtenemos los datos de la fila seleccionada
              var row = $(this).closest('tr');
              var datos = self.config_table_usuarios.row(row).data();
              var registros = controller.get("dataTable");

              if (registros.length > 0) {
                // Buscamos todos los datos de usuario en el arreglo original
                var datos_usuario = registros.find(function (obj) {
                  return obj.usuario_k == datos[0];
                });
                var otros_roles = datos_usuario.otros_roles.length > 0 ? datos_usuario.otros_roles.split(",") : [];
                otros_roles.push(datos_usuario.rol_k);
                self.config_modal_usuario = {
                  titulomodal: "Edición de Usuario",
                  usuario_k: datos_usuario.usuario_k,
                  nombreusuario: datos_usuario.nombre,
                  apellidopaterno: datos_usuario.apellido_paterno,
                  apellidomaterno: datos_usuario.apellido_materno,
                  genero: datos_usuario.genero,
                  email: datos_usuario.email,
                  sucursal_k: datos_usuario.sucursal_k,
                  departamento_k: datos_usuario.departamento_k,
                  puesto_k: datos_usuario.puesto_k,
                  visibleComunidad: false,
                  username: datos_usuario.username,
                  rol_k: datos_usuario.rol_k,
                  otros_roles: otros_roles,
                  esEdicion: true
                }; // Indicamos los roles seleccionados

                var lista_roles = controller.get('roles');
                var lista_roles_tmp = [];
                lista_roles.forEach(function (rol) {
                  lista_roles_tmp.push({
                    rol: rol.rol,
                    rol_k: rol.rol_k,
                    checked: otros_roles.includes(rol.rol_k)
                  });
                }); // Seteamos los datos del formulario

                controller.set('roles', lista_roles_tmp);
                controller.set('config_modal_usuario', self.get("config_modal_usuario"));
                $('#nuevoUsuarioModal').modal('show');
              } else {
                showModal({
                  titulo: 'Edición de Usuario.',
                  mensaje: 'Registro no encontrado, consulte a sistemas.'
                });
                return false;
              }
            }); // Reenvio de contraseña

            $('#usuariosTable tbody').on('click', '.btn_enviarcontrasenia', function (event) {
              // Obtenemos los datos de la fila seleccionada
              var row = $(this).closest('tr');
              var datos = self.config_table_usuarios.row(row).data();
              self.reenvio_de_claves(controller, datos);
            }); // Cambiar contraseña 

            $('#usuariosTable tbody').on('click', '.btn_editarcontrasenia', function (event) {
              // Obtenemos los datos de la fila seleccionada
              var row = $(this).closest('tr');
              var datos = self.config_table_usuarios.row(row).data();
              self.mdl_cambio_de_contrasenia(controller, datos);
            });
            $("#loading").fadeOut(1000);
          }, 1000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_sucursales: function carga_sucursales(controller) {
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarsucursales',
        type: 'POST',
        data: {},
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('sucursales', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_departamento: function carga_departamento(controller) {
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listardepartamentos',
        type: 'POST',
        data: {},
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('departamentos', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_puestos: function carga_puestos(controller) {
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/listarpuestos',
        type: 'POST',
        data: {},
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('puestos', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    form_nuevousuario_clearinput: function form_nuevousuario_clearinput() {
      $('#form_nuevousuario').trigger("reset");
    },
    form_nuevoelemento_clearinput: function form_nuevoelemento_clearinput() {
      $('#form_nuevoelemento').trigger("reset");
    },
    form_nuevousuario_removeinvalid: function form_nuevousuario_removeinvalid() {
      $("#form_nuevousuario input[type='text']").removeClass("is-invalid");
      $("#form_nuevousuario select").removeClass("is-invalid");
      $(".frm_rol").each(function () {
        $(this).removeClass("is-invalid");
      });
    },
    reenvio_de_claves: function reenvio_de_claves(controller) {
      var datos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : array();
      var self = this;
      var usuario_k = datos[0];
      var nombre_completo = datos[1];
      var email = datos[2];
      var username = datos[4];
      self.mdl_confirm = {
        descripcion: "<h5 slign='center'>¿Deseas reenviar las claves del siguiente usuario?</h5> <p><b>" + nombre_completo + "</b></p> <p class='mt-2'><b>Email: </b>" + email + "</p>",
        accion: "reenvio_claves",
        titulo_boton: "Enviar",
        datos: {
          usuario_k: usuario_k,
          username: username,
          nombre_completo: nombre_completo,
          email: email
        }
      };
      controller.set('mdl_confirm', self.get("mdl_confirm"));
      $('#confirm_delete_modal').modal('show');
    },
    fn_reenvio_de_claves: function fn_reenvio_de_claves() {
      var datos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : array();
      var parametros = new FormData();
      parametros.append('usuario_k', datos.usuario_k);
      parametros.append('username', datos.username);
      parametros.append('nombre_completo', datos.nombre_completo);
      parametros.append('email', datos.email);
      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/reenvioclaves',
        type: 'POST',
        data: parametros,
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        success: function success(html) {
          var response = JSON.parse(html);

          if (response.band) {
            showModal({
              titulo: 'Accesos',
              mensaje: 'Se enviaron correctamente los accesos del usuario.'
            });
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    mdl_cambio_de_contrasenia: function mdl_cambio_de_contrasenia(controller) {
      var datos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : array();
      var self = this;
      var usuario_k = datos[0];
      var nombre_completo = datos[1];
      var email = datos[2];
      var username = datos[4];
      self.config_passwords = {
        password_uno: "",
        password_dos: "",
        notificacion: false,
        datos: {
          usuario_k: usuario_k,
          username: username,
          nombre_completo: nombre_completo,
          email: email
        }
      };
      console.log(self.get("config_passwords"));
      controller.set('config_passwords', self.get("config_passwords"));
      $('#cambioPassModal').modal('show');
    },
    id_unico: function id_unico() {
      var rand = Math.floor(Math.random() * 26) + Date.now();
      return rand++;
    },
    cargar_archivos: function cargar_archivos() {
      var self = this; // Obtenemos los archivos.

      var catalogo_centros = $("#file_centros").prop('files');
      var catalogo_usuarios = $("#file_usuarios").prop('files');
      var parametros = new FormData();
      var band = true;
      var nombre_centro = "centro_" + self.id_unico() + ".csv";
      var nombre_usuarios = "usuarios_" + self.id_unico() + ".csv";
      self.nombre_archivos.centros = nombre_centro;
      self.nombre_archivos.usuarios = nombre_usuarios; // No se valida el archivo de Centros por que es posible que ya existan.
      // Validamos que carguen el layout de Usuarios.

      if (catalogo_usuarios.length > 0) {
        parametros.append("archivo_usuarios", catalogo_usuarios[0]);
      } else {
        showModal({
          titulo: 'Carga de archivos',
          mensaje: 'Es necesario cargar el layout de Usuarios.'
        });
        band = false;
      }

      if (catalogo_centros.length > 0) {
        parametros.append("archivo_centros", catalogo_centros[0]);
      } else {
        self.nombre_archivos.centros = ''; // parametros.append( "archivo_centros", "" );
      } // Enviamos los archivos


      if (band) {
        parametros.append("nombre_archivo_centro", nombre_centro);
        parametros.append("nombre_archivo_usuarios", nombre_usuarios);

        self._controller.set('msj_cargamasiva', 'Cargando archivos...');

        $.ajax({
          url: constant.APIURL + 'lms/administradorusuarios/cargar_archivos',
          type: "POST",
          data: parametros,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          async: false,
          beforeSend: function beforeSend() {
            self._controller.set('btn_cargamasiva_siguiente', true);

            console.log('deshabilitar: ', self._controller.get('msj_cargamasiva'));
          },
          success: function success(result) {
            var response = JSON.parse(result);

            if (response.band) {
              self.analisis_de_archivos();
            } else {
              showModal({
                titulo: 'Ocurrio un error al enviar los datos del Usuario.',
                mensaje: response.msj
              });
              band = false;
            }

            self._controller.set('btn_cargamasiva_siguiente', false);

            self._controller.set('msj_cargamasiva', '');
          },
          error: function error(e) {
            console.log(e);

            self._controller.set('btn_cargamasiva_siguiente', false);

            self._controller.set('msj_cargamasiva', '');
          }
        });
      }

      return band;
    },
    analisis_de_archivos: function analisis_de_archivos() {
      var self = this;
      var parametros = new FormData();
      parametros.append('archivo_centros', self.nombre_archivos.centros);
      parametros.append('archivo_usuarios', self.nombre_archivos.usuarios);

      self._controller.set('msj_cargamasiva', 'Analisis de archivos...');

      self._controller.set('analisis_cargamasiva', '');

      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/analisar_archivos',
        type: "POST",
        data: parametros,
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        async: false,
        beforeSend: function beforeSend() {
          self._controller.set('btn_cargamasiva_siguiente', true);

          console.log('deshabilitar 2: ', self._controller.get('msj_cargamasiva'));
        },
        success: function success(result) {
          self._controller.set('btn_cargamasiva_siguiente', false);

          self._controller.set('msj_cargamasiva', '');

          self._controller.set('analisis_cargamasiva', result);

          setTimeout(function () {
            // Tabs
            $(".tab-info_masivo").on("click", function () {
              var elemento = $(this);
              var tag = elemento.data("tab-target");
              console.log('tag: ', tag);
              $(".tab-info_masivo").removeClass("is-active");
              elemento.addClass("is-active");
              $(".tabs__pane").removeClass("is-active");
              $(tag).addClass("is-active");
            });
          }, 500);
        },
        error: function error(e) {
          console.log(e);

          self._controller.set('btn_cargamasiva_siguiente', false);

          self._controller.set('msj_cargamasiva', '');
        }
      });
    },
    fn_carga_masiva: function fn_carga_masiva() {
      var tab_usuarios = $("#tab-usuarios-cargamasiva");
      var usuarios_repetidos = tab_usuarios.data("usuariosrepetidos");
      var band_centros = $("#band_centros").val(); // Valida que los Centros del Layout existan en la BD o el archivo.

      var self = this;
      var parametros = new FormData();

      if (usuarios_repetidos > 0) {
        showModal({
          titulo: 'Validación',
          mensaje: 'Verifica que no existan usuarios repetidos.'
        });
        return false;
      } // Valida que los Centros del Layout existan en la BD o el archivo.


      if (band_centros === '1') {
        showModal({
          titulo: 'Validación',
          mensaje: 'Verifica que los Centros existan en la BD o estan cargados en el archivo.'
        });
        return false;
      }

      parametros.append('archivo_centros', self.nombre_archivos.centros);
      parametros.append('archivo_usuarios', self.nombre_archivos.usuarios);

      self._controller.set('msj_cargamasiva', 'Ejecutando carga masiva...');

      $.ajax({
        url: constant.APIURL + 'lms/administradorusuarios/ejecutar_carga',
        type: "POST",
        data: parametros,
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        async: false,
        beforeSend: function beforeSend() {
          self._controller.set('btn_carga_masiva', true);
        },
        success: function success(result) {
          var resp = JSON.parse(result);

          self._controller.set('msj_cargamasiva', '');

          showModal({
            titulo: 'Alta de registros',
            mensaje: resp.msj
          });

          if (resp.band) {
            $('#cargaMasivaModal').modal('hide');
          }
        },
        error: function error(e) {
          self._controller.set('btn_carga_masiva', false);

          self._controller.set('msj_cargamasiva', '');
        }
      });
    },
    actions: {
      guardarDatos: function guardarDatos() {
        var self = this,
            regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
            error = false,
            inputerror = [];
        self.form_nuevousuario_removeinvalid();
        var nombre = $("#frm_nombre").val().trim(),
            ap_paterno = $("#frm_apellido_paterno").val().trim(),
            ape_materno = $("#frm_apellido_materno").val().trim(),
            genero = $("#frm_genero").val().trim(),
            email = $("#frm_correo").val().trim(),
            sucursal = $("#frm_sucursal").val().trim(),
            departamento = $("#frm_departamento").val().trim(),
            puesto = $("#frm_puesto").val().trim(),
            total_comunidades = parseInt($("#total_comunidades").val()),
            comunidad = !self.config_modal_usuario.esEdicion ? $("#frm_comunidad").val().trim() : '',
            username = $("#frm_username").val().trim(),
            estatus = 1,
            // $("#frm_estatus").val().trim()
        roles = [],
            url = self.config_modal_usuario.esEdicion ? 'edicionUsuario' : 'altadeusuario',
            usuario_k = self.config_modal_usuario.usuario_k,
            registro_nuevo = self.config_modal_usuario.esEdicion ? '1' : '0',
            msj_titulo = "Alta de Usuario.",
            msj_descripcion = "El usuario ha sido dado de alta correctamente.<br>Los <b>ACCESOS</b> a la plataforma fueron enviados al correo registrado.";

        if (self.config_modal_usuario.esEdicion) {
          msj_titulo = "Edición de Usuario.", msj_descripcion = "Datos modificados correctamente!!.";
        } // Validaciones del formulario


        if (nombre == "" || nombre.length <= 3) {
          inputerror.push("#frm_nombre");
          error = true;
        }

        if (ap_paterno == "" || ap_paterno.length <= 3) {
          inputerror.push("#frm_apellido_paterno");
          error = true;
        }

        if (ape_materno == "" || ape_materno.length <= 3) {
          inputerror.push("#frm_apellido_materno");
          error = true;
        }

        if (genero == "") {
          inputerror.push("#frm_genero");
          error = true;
        }

        if (!regex.test(email) || email == "") {
          inputerror.push("#frm_correo");
          error = true;
        }

        if (sucursal == "" || sucursal == "OTRO") {
          inputerror.push("#frm_sucursal");
          error = true;
        }

        if (departamento == "" || departamento == "OTRO") {
          inputerror.push("#frm_departamento");
          error = true;
        }

        if (puesto == "" || puesto == "OTRO") {
          inputerror.push("#frm_puesto");
          error = true;
        }

        if (username == "" || username.length <= 3) {
          inputerror.push("#frm_username");
          error = true;
        } // Validamos la comunidad si el registro es NUEVO


        if (!self.config_modal_usuario.esEdicion) {
          if (total_comunidades > 1 && comunidad == "") {
            inputerror.push("#frm_comunidad");
            error = true;
          } else if (total_comunidades === 0) {
            // Empresa sin comunidad
            showModal({
              titulo: 'Comunidad.',
              mensaje: 'Es nesesario tener una comunidad asociada.'
            });
            error = true;
            return false;
          }
        } // Validamos el Rol


        $(".frm_rol:checked").each(function () {
          roles.push($(this).val());
        });

        if (roles.length === 0) {
          $(".frm_rol").each(function () {
            $(this).addClass("is-invalid");
          });
          error = true;
        } // Mostramos los primeros errores al usuario


        if (error) {
          inputerror.forEach(function (valor, indice, array) {
            if (indice === 0) {
              $(valor).focus();
            }

            $(valor).addClass("is-invalid");
          });
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Verifica que todos los campos estén llenos correctamente.'
          });
          return false;
        }

        $("#loading").show(); // Enviamos parametros

        if (!error) {
          var datos = new FormData();
          datos.append('nombre', nombre);
          datos.append('ap_paterno', ap_paterno);
          datos.append('ape_materno', ape_materno);
          datos.append('genero', genero);
          datos.append('email', email);
          datos.append('sucursal', sucursal);
          datos.append('departamento', departamento);
          datos.append('puesto', puesto);
          datos.append('comunidad', comunidad);
          datos.append('username', username);
          datos.append('estatus', estatus);
          datos.append('roles', roles);
          datos.append('usuario_k', usuario_k);
          datos.append('registro_nuevo', registro_nuevo);
          $.ajax({
            url: constant.APIURL + 'lms/administradorusuarios/' + url,
            type: 'POST',
            data: datos,
            processData: false,
            contentType: false,
            xhrFields: {
              withCredentials: true
            },
            success: function success(html) {
              $("#loading").fadeOut(1000);
              var response = JSON.parse(html);

              if (response.band) {
                self.config_table_usuarios.clear().destroy(); // Destruimos la configuración de la tabla

                self.carga_de_usuarios(self._controller); // Cargamos los usuarios

                self.form_nuevousuario_clearinput(); // Limpiamos el formulario

                $('#nuevoUsuarioModal').modal('hide'); // Ocultamos el formulario de alta

                showModal({
                  titulo: msj_titulo,
                  mensaje: msj_descripcion
                });
              } else {
                showModal({
                  titulo: 'Ocurrio un error al enviar los datos del Usuario.',
                  mensaje: response.msj
                });
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        }
      },
      cerrar_elemento: function cerrar_elemento() {
        var tipo = $("#tipoElemento").val().trim(),
            self = this;

        if (tipo === 'SUCURSAL') {
          $("#frm_sucursal").prop('selectedIndex', 0);
        } else if (tipo === 'DEPARTAMENTO') {
          $("#frm_departamento").prop('selectedIndex', 0);
        } else if (tipo === 'PUESTO') {
          $("#frm_puesto").prop('selectedIndex', 0);
        }

        self.form_nuevoelemento_clearinput();
        $('#nuevoElementoModal').modal('hide');
      },
      agregar_elemento: function agregar_elemento() {
        var nombre = $("#frm_elemento_nombre").val().trim(),
            tipo = $("#tipoElemento").val().trim(),
            url = '',
            self = this,
            datos = new FormData(); // Validacion del Nombre

        if (nombre.length === 0) {
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Es necesario ingresar el nombre.'
          });
          return false;
        } // Validacion del Nombre


        if (nombre === 'OTRO') {
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Ingrese un Nombre para el registro valido.'
          });
          return false;
        } // Definimos URL para la alta del elemento


        if (tipo === 'SUCURSAL') {
          url = constant.APIURL + 'lms/administradorusuarios/altasucursal';
        } else if (tipo === 'DEPARTAMENTO') {
          url = constant.APIURL + 'lms/administradorusuarios/altadepartamento';
        } else if (tipo === 'PUESTO') {
          url = constant.APIURL + 'lms/administradorusuarios/altapuesto';
        }

        datos.append('nombre', nombre);
        $.ajax({
          url: url,
          type: 'POST',
          data: datos,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          success: function success(html) {
            var response = JSON.parse(html);

            if (response.band) {
              self.form_nuevoelemento_clearinput(); // Limpiamos el formulario

              $('#nuevoElementoModal').modal('hide'); // Ocultamos el formulario de alta

              if (tipo === 'SUCURSAL') {
                self.set('sucursal_usuario', nombre);
                self.carga_sucursales(self._controller); // $('#frm_sucursal').val( nombre );
              } else if (tipo === 'DEPARTAMENTO') {
                self.carga_departamento(self._controller);
                $('#frm_departamento').val(nombre);
              } else if (tipo === 'PUESTO') {
                self.carga_puestos(self._controller); // $('#frm_puesto').val( nombre );

                $('#frm_puesto option[value=' + nombre + ']').attr('selected', 'selected');
              }

              showModal({
                titulo: 'Alta de ' + tipo,
                mensaje: "Registro dado de alta correctamente."
              });
            } else {
              showModal({
                titulo: 'Error en el alta de ' + tipo,
                mensaje: response.msj
              });
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      confirm: function confirm() {
        var self = this;
        var datos = self.get("mdl_confirm");

        if (datos.accion === "reenvio_claves") {
          self.fn_reenvio_de_claves(datos.datos);
        } else if (datos.accion === "ejecutar_cargamasiva") {
          self.fn_carga_masiva();
        }
      },
      cerrar_cambiopass: function cerrar_cambiopass() {
        $('#cambioPassModal').modal('hide');
      },
      cambio_de_contrasenia: function cambio_de_contrasenia() {
        var self = this;
        var errores = {
          band: false,
          mensaje: ""
        };

        var datos = self._controller.get("config_passwords"); // Validamos las contraseñas


        $("#frm_contrasenia_uno, #frm_contrasenia_dos").removeClass("is-invalid");

        if (datos.password_uno.trim() === "" || datos.password_dos.trim() === "") {
          $("#frm_contrasenia_uno, #frm_contrasenia_dos").addClass("is-invalid");
          showModal({
            titulo: 'Validación',
            mensaje: 'Verifica que los campos no esten vacios.'
          });
          return false;
        }

        if (datos.password_uno !== datos.password_dos) {
          $("#frm_contrasenia_uno, #frm_contrasenia_dos").addClass("is-invalid");
          showModal({
            titulo: 'Validación',
            mensaje: 'Verifica que las contraseñas sean iguales.'
          });
          return false;
        } // Validar el correo del usuario en caso de ser requerido


        if (datos.notificacion && (datos.datos.email == "" || datos.datos.email.length == 0)) {
          $("#frm_contrasenia_uno, #frm_contrasenia_dos").addClass("is-invalid");
          showModal({
            titulo: 'Validación',
            mensaje: 'Verifica que el correo del usuario no esté vacío.'
          });
          return false;
        } // Deberá ser mayor a 7 caracteres


        if (datos.password_uno.length < 8) {
          errores.band = true;
          errores.mensaje += "<br />- Deberá ser mayor a 7 caracteres";
        } // Deberá tener una letra minúscula.


        if (!datos.password_uno.match(/[a-z]/)) {
          errores.band = true;
          errores.mensaje += "<br />- Deberá tener una letra minúscula.";
        } // Deberá tener una letra mayúscula.


        if (!datos.password_uno.match(/[A-Z]/)) {
          errores.band = true;
          errores.mensaje += "<br />- Deberá tener una letra mayúscula.";
        } // Deberá tener un número.


        if (!datos.password_uno.match(/\d/)) {
          errores.band = true;
          errores.mensaje += "<br />- Deberá tener un número.";
        } // Deberá tener un caracter especial


        if (!datos.password_uno.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
          errores.band = true;
          errores.mensaje += "<br />- Deberá tener un caracter especial ( !,@,#,$,%,^,&,*,?,_,~,-,(,) ).";
        }

        if (errores.band) {
          showModal({
            titulo: 'Validación',
            mensaje: 'Verifica que se cumplan las siguientes reglas en la contraseña:' + errores.mensaje
          });
          return false;
        }

        var parametros = new FormData();
        parametros.append('password', datos.password_uno);
        parametros.append('notificacion', datos.notificacion);
        parametros.append('datos', JSON.stringify(datos.datos));
        $.ajax({
          url: constant.APIURL + 'lms/administradorusuarios/cambiarPassword',
          type: 'POST',
          data: parametros,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          success: function success(html) {
            console.log(html);
            var response = JSON.parse(html);

            if (response.band) {
              $('#cambioPassModal').modal('hide'); // Ocultamos el formulario de alta

              showModal({
                titulo: "Cambio de Contraseña",
                mensaje: "El cambio de contraseña se ha realizado correctamente."
              });
            } else {
              showModal({
                titulo: "Cambio de Contraseña",
                mensaje: "Ocurrio un error en el cambio de contraseña."
              });
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      cm_siguiente: function cm_siguiente() {
        var self = this;

        if (self.cargar_archivos()) {
          this.config_pantallas = {
            isPantallaUno: false,
            isPantallaDos: true
          };

          self._controller.set('config_pantallas', self.get("config_pantallas"));
        }
      },
      cm_atras: function cm_atras() {
        var self = this;
        this.config_pantallas = {
          isPantallaUno: true,
          isPantallaDos: false
        };

        self._controller.set('config_pantallas', self.get("config_pantallas"));
      },
      ejecutar_carga: function ejecutar_carga() {
        var self = this;
        self.mdl_confirm = {
          descripcion: "<h5 slign='center'>¿Estas seguro de realizar la carga masiva?</h5>",
          accion: "ejecutar_cargamasiva",
          titulo_boton: "Ejecutar",
          datos: {}
        };

        self._controller.set('mdl_confirm', self.get("mdl_confirm"));

        $('#confirm_delete_modal').modal('show');
      }
    }
  });

  _exports.default = _default;
});